"use client";
import { useTranslation } from "@/app/i18n/client";
import { Button } from "antd";
// Next Imports
import Lottie from "lottie-react";
import { useParams } from "next/navigation";

export const NotFound = () => {
  const { lng } = useParams();
  const { t } = useTranslation(lng, "common");
  return (
    <div className="flex items-center flex-col text-center py-24">
      <div className="flex flex-col gap-2 is-[90vw] sm:is-[unset] mbe-6">
        <span className="font-medium text-8xl" color="text.primary">
          {t("404")}
        </span>
        <h4 className="text-2xl font-medium">{t("page_not_found")}</h4>
        <span>{t("not_found_message")}</span>
      </div>
      <Button href={`/${lng}/`} type="primary" className="mt-5">
        {t("back_to_home")}
      </Button>
      <Lottie
        animationData={require("../../public/assets/animations/search.json")}
        loop={true}
        className="w-102 h-102"
      />
    </div>
  );
};

export default NotFound;
